<template>
    <div>
        <div class="card-body" style="border:1px solid #e9dbdb;font-size:13px;" v-if="searchdetails">
            <div class="col-md-12">
                <div class="row">
                    <label class="col-lg-4 co-md-3 control-label ">
                        <h5> Search Records</h5>
                    </label>
                </div>
                <div class="form-group mb-2 ">
                    <div class="row">
                        <div class="col-md-4 row">
                            <label><b>Registration Type</b></label>
                            <select v-model="registation_type" class="form-control form-cascade-control input-small">
                                <option v-for="option in getmastertype(MASTER_REGISTRATION_TYPE)"
                                    v-bind:key="option.labelid" v-bind:value="option.labelid" v-text="option.label" />
                            </select>
                        </div>
                        <div class="col-md-4 row">
                            <label><b> Customer Name</b></label>
                            <input class="form-control form-cascade-control input-small" type="text"
                                v-model="customername">
                        </div>
                        <div class="col-md-4 row">
                            <label><strong>VSDIGI Id</strong></label>
                            <input class="form-control form-cascade-control input-small" type="text"
                                v-model="generatedid">
                        </div>
                        <div class="col-md-4 row">
                            <label><strong>Mobile</strong></label>
                            <input class="form-control form-cascade-control input-small" type="number" v-model="mobile">
                        </div>
                        <div class="col-md-4 row">
                            <label><strong>Address</strong></label>
                            <input class="form-control form-cascade-control input-small" type="text" v-model="address">
                        </div>
                        <div class="col-md-4 row">
                            <label><strong>Aadhar No</strong></label>
                            <input class="form-control form-cascade-control input-small" type="number"
                                v-model="aadharno">
                        </div>
                        <div class="col-md-4 row">
                            <label><strong>D.O.B : From</strong></label>
                            <input class="form-control form-cascade-control input-small" v-model="dobfrom" type="date">
                        </div>
                        <div class="col-md-4 row">
                            <label><strong>D.O.B : To</strong></label>
                            <input class="form-control form-cascade-control input-small" v-model="dobto" type="date">
                        </div>
                        <div class="col-md-4 row">
                            <label><strong>By Gender</strong></label>
                            <select v-model="gender" class="form-control form-cascade-control input-small">
                                <option v-for="option in getmastertype(MASTER_GENDER)" v-bind:key="option.labelid"
                                    v-bind:value="option.labelid" v-text="option.label" />
                            </select>
                        </div>
                        <div class="col-md-4 row">
                            <label><strong>Marriage : From</strong></label>
                            <input class="form-control form-cascade-control input-small" v-model="marriagefrom"
                                type="date">
                        </div>
                        <div class="col-md-4 row">
                            <label><strong>Marriage : To</strong></label>
                            <input class="form-control form-cascade-control input-small" v-model="marriageto"
                                type="date">
                        </div>
                        <div class="col-md-4 row">
                            <label><strong>Email</strong></label>
                            <input class="form-control form-cascade-control input-small" v-model="email" type="text">
                        </div>
                        <div class="col-md-4 row">
                            <label><strong>By Religion</strong></label>
                            <select v-model="religion" class="form-control form-cascade-control input-small">
                                <option v-for="option in getmastertype(MASTER_RELIGION)" v-bind:key="option.labelid"
                                    v-bind:value="option.labelid" v-text="option.label" />
                            </select>
                        </div>

                        <div class="col-md-4 row">
                            <label><strong>Registration From</strong></label>
                            <input class="form-control form-cascade-control input-small" data-val="true"
                                v-model="registation_datefrom" data-val-required="Required" id="REGDATE" name="REGDATE"
                                type="date">
                        </div>
                        <div class="col-md-4 row">
                            <label><strong>Registration To</strong></label>
                            <input class="form-control form-cascade-control input-small" data-val="true"
                                v-model="registation_dateto" data-val-required="Required" id="REGDATE" name="REGDATE"
                                type="date">
                        </div>
                        <div class="col-md-4 row">
                            <label><strong>Pincode</strong></label>
                            <input class="form-control form-cascade-control input-small" data-val="true"
                                v-model="pincode" data-val-required="Required" id="REGDATE" name="REGDATE" type="text">
                        </div>
                        <div class="col-md-4 row">
                            <label><strong>Rating</strong></label>
                            <select class="form-control form-cascade-control input-small" v-model="customer_type">
                                <option value="A">A</option>
                                <option value="B">B</option>
                                <option value="C">C</option>
                                <option value="D">D</option>
                            </select>
                        </div>
                        <div class="col-md-4 row">
                            <label><strong>Introducer Id</strong></label>
                            <input class="form-control form-cascade-control input-small" v-model="introducerid"
                                type="text" @input="fetchIntroducer()" placeholder="VSDIGI-ID">
                        </div>
                        <div class="col-md-4" v-if="loginusercheck(271)">
                            <div class="row mt-20">
                                <div class="col-md-6 p-0">
                                    <select @change="chooseUser()" v-model="userrecord"
                                        class="form-control input-small">
                                        <option value="0">Select Page</option>
                                        <option v-for="item in noofuser" :key="item" :value="item">{{ item }}</option>
                                    </select>
                                </div>
                                <div class="col-md-6">
                                    <button @click="sendMessage()" class="btn btn-success input-small">
                                        <i class='bx bxl-whatsapp'></i>WhatsApp
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                        </div>
                        <div class="col-md-4 row">
                            <strong>{{ this.introducer.name }} </strong> , <span> {{ this.introducer.mobile }}</span>
                            <br>
                        </div>
                    </div>
                    <div class="col-md-12 " style="display: flex;align-items: end;">
                        <div class="col-md-5 " style="display: flex;align-items: center;">
                            <div class="col-md-4">
                                <strong>Show Rec.</strong>
                            </div>
                            <div class="col-md-4">
                                <select class="form-control col-mb-1" v-model="noofrec" id="rec" @change="search()">
                                    <option value=10>10</option>
                                    <option value=25>25</option>
                                    <option value=50>50</option>
                                    <option value=100>100</option>
                                    <option value=200>200</option>
                                    <option value=500>500</option>
                                    <option value=1000>1000</option>
                                </select>
                            </div>
                            <div class="col-md-4">
                                <button class="btn btn-primary" @click="exportExcel()">
                                    Excel
                                </button>
                            </div>
                        </div>
                        <div class="col-md-3"></div>

                        <div class="col-md-4 flex-around-row">
                            <button @click="search()" class="btn bg-success text-white username btnsearch"
                                style="margin-top:31px;" type="button"><i class='bx bx-search-alt-2'></i>
                                Search</button>
                            <button @click="reset()" class="btn bg-primary text-white username btnsearch"
                                style="margin-top:31px;" type="button"><i class="fa fa-times"></i> Clear</button>
                            <button @click="fetchLottery()" class="btn bg-primary text-white username btnsearch"
                                style="margin-top:31px;" type="button">Lucky Winner</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal name="lotteryShow" :width="500" :height="280" draggable="window-header">
            <div class="col-md-12 pb-10 text-center">
                <h3 for="remark" class="window-header" style="border-bottom: 1px solid green;">Lottery</h3>
            </div>
            <div class="col-md-12">
                <p style="color:green">Congratulations, You are our {{ this.position ?? 'N/A' }} lucky winner.</p>
            </div>
            <div class="col-md-12 row">
                <div class="col-md-4">
                    <label for="remark">Customer name</label>
                </div>
                <div class="col-md-8">
                    <div class="form-control input-small">{{ this.selecteduser?.name }} ({{
                        this.selecteduser?.generatedid }})</div>
                </div>
            </div>
            <div class="col-md-12 row mt-20">
                <div class="col-md-4">
                    <label for="remark">Reward</label>
                </div>
                <div class="col-md-8">
                    <textarea class="form-control " v-model="rewards"></textarea>
                </div>
            </div>
            <div class="col-md-12 flex-between-row pt-20">
                <button class="btn bg-danger text-white btn-sm chkcondition" @click="close()">Cancel</button>
                <button class="btn bg-success text-white btn-sm chkcondition" @click="lotterysave()">Submit</button>
            </div>
        </modal>
    </div>
</template>
<script>
import debounce from 'lodash.debounce';
import Constants from '../../components/utilities/Constants.vue'
import { mapGetters } from 'vuex'
import moment from 'moment';
import axios from 'axios'
export default {
    props: ['displayfor'],
    mixins: [Constants],
    data() {
        return {
            registation_type: 0,
            customername: '',
            mobile: '',
            address: '',
            generatedid: '',
            aadharno: '',
            dobfrom: '',
            dobto: '',
            registation_datefrom: '',
            registation_dateto: '',
            religion: 0,
            gender: 0,
            email: null,
            searchdetails: true,
            pincode: 0,
            userid: [],
            marriagefrom: '',
            marriageto: '',
            noofuser: 0,
            userrecord: 0,
            page: 1,
            noofrec: 10,
            alluser: [],
            customer_type: '',
            introducerid: '',
            introducer: {
                name: '',
                mobile: '',
                address: '',
            },
            selecteduser: null,
            rewards: '',
            moment:moment,
            position:0,
        }
    },
    computed: {
        ...mapGetters([
            'searchdata', 'userids', 'currentpage'
        ]),

    },
    watch: {
        $route: function (to, from) {
            console.log(to)
            console.log(from)
            if (this.$route.params.id != null) {
                //console.log(this.$route.params.id)
                // if(this.searchdata.search=="vsdigi"){
                //     this.reset();
                //     this.generatedid=this.$route.params.id
                // }else if(this.searchdata.search=="number"){
                //     this.reset();
                //     this.mobile=this.$route.params.id
                // }else if(this.searchdata.search=="email"){
                //     this.reset();
                //     this.email=this.$route.params.id
                // }
                // else if(this.searchdata.search=="name"){
                //     this.reset();
                //     this.customername=this.$route.params.id
                // }
                this.searchdetails = false
            } else {
                this.searchdetails = true
                this.refresh();
            }
        },
        noofuser() {
            return Math.ceil(parseInt(this.alluser?.length) / parseInt(this.noofrec))
        }
    },
    mounted() {
        if (this.$route.params.id != null) {

            this.searchdetails = false
        } else {
            this.searchdetails = true
            this.refresh();
        }
    },
    methods: {
        reset() {
            this.registation_type = 0,
                this.customername = '',
                this.mobile = '',
                this.address = '',
                this.generatedid = '',
                this.aadharno = '',
                this.dobfrom = '',
                this.dobto = '',
                this.marriagefrom = ''
            this.marriageto = ''
            this.registation_date = '',
                this.religion = 0,
                this.gender = 0,
                this.email = null,
                this.searchdetails = true
            this.pincode = 0
            this.noofuser = 0
            this.customer_type = ''
            this.introducerid = ''
            this.introducer.name = '',
                this.introducer.mobile = ''
            this.introducer.address = ''
        },
        refresh() {
            this.searchdetails = true
            this.reset();
        },

        search() {
            this.$store.commit("assignloadingstatus", 1)
            this.$store.commit('assignnoofrecord', this.noofrec)
            let param = {
                email: this.email, registation_datefrom: this.registation_datefrom, religion: this.religion,
                gender: this.gender, registation_type: this.registation_type, name: this.customername, introducerid: this.introducerid,
                mobile: this.mobile, address: this.address, generatedid: this.generatedid, registation_dateto: this.registation_dateto,
                iscustomer: 1, aadharno: this.aadharno, dobfrom: this.dobfrom, dobto: this.dobto, pincode: this.pincode, noofrec: this.noofrec,
                currentpage: this.currentpage, marriagefrom: this.marriagefrom, marriageto: this.marriageto, customer_type: this.customer_type,
            }
            if (this.displayfor == 1) {
                this.$http.post('api/users/fetch', param)
                    .then((response) => this.processResponse(response.data))
                    .catch((err) => {
                        console.log(err)
                    });
                this.$http.post('api/users/fetch/count', param)
                    .then((response) => this.processResponseCount(response.data))
                    .catch((err) => {
                        this.errorShow(err)
                    });
                this.$http.post('api/users/fetch/send/message', param) // this api also use for lottery
                    .then((response) => this.processResponseSendMessageUser(response.data))
                    .catch((err) => {
                        console.log(err)
                    });
            }
        },
        processResponseSendMessageUser(data) {
            this.alluser = data.userlist

            this.$store.commit('assignusermobiles', null)
            // console.log("user ids", this.alluser)
            this.noofuser = Math.ceil(parseInt(this.alluser?.length) / parseInt(this.noofrec))


        },
        processResponseCount(data) {
            this.$store.commit('assigncustomerscount', data)
        },
        processResponse(data) {
            this.$store.commit("assignloadingstatus", 0)
            this.$store.commit('assigncustomers', data)
        },
        processResponseCRM(data) {
            this.$store.commit("assignloadingstatus", 0)
            this.$store.commit('assigncrmschedule', data)
        },
        ProcessCRMcount(data) {
            this.$store.commit("assignloadingstatus", 0)
            this.$store.commit('assigncrmschedulecount', data)
        },
        sendMessage() {
            if (this.userids?.length > 0) {
                this.$store.commit('assignloadingstatus', 1)
                this.$store.commit('assignedititem', null)
                setTimeout(() => {
                    this.$store.commit('assignviewno', 4)
                    this.$store.commit('assignloadingstatus', 0)
                }, 500);
            } else {
                alert('Select page no.')
            }
        },
        chooseUser() {
            // console.log("no of rec", this.userrecord)
            let value = this.alluser?.slice((this.userrecord - 1) * this.noofrec, (this.userrecord - 1) * this.noofrec + this.noofrec);
            this.$store.commit('assignuserids', value)

        },
        exportExcel() {
            let param = {
                email: this.email, registation_datefrom: this.registation_datefrom, religion: this.religion,
                gender: this.gender, registation_type: this.registation_type, name: this.customername,
                mobile: this.mobile, address: this.address, generatedid: this.generatedid, registation_dateto: this.registation_dateto,
                iscustomer: 1, aadharno: this.aadharno, dobfrom: this.dobfrom, dobto: this.dobto, pincode: this.pincode, noofrec: this.noofrec,
                currentpage: this.currentpage, marriagefrom: this.marriagefrom, marriageto: this.marriageto, customer_type: this.customer_type
            }
            this.$http({
                url: 'api/customer/exportdata',
                method: 'post',
                responseType: 'blob',
                data: param,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    mode: 'no-cors'
                }
            })
                .then(response => {
                    let blob = new Blob([response.data], { type: 'application/vnd.ms-excel' })
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = 'customer_report.xlsx'
                    link.click()
                    this.flagexl = 0
                })
        },
        fetchIntroducer: debounce(
            function () {
                if (this.introducerid != "") {
                    let param = { generatedid: this.introducerid }
                    this.$http.post('api/user/getuserbygeneratedid', param)
                        .then(response => this.processUserResponse(response.data))
                        .catch(error => console.log(error));
                } else {
                    this.introducer.name = ''
                    this.introducer.mobile = ''
                    // this.introducer.address=''
                }

            },
            1000
        ),
        processUserResponse(data) {
            this.introducer.name = data.name
            if (data.mobiles?.length > 0) {
                this.introducer.mobile = data.mobiles[0].mobile
            }
            if (data.addressess?.length > 0) {
                this.introducer.address = data.addressess[0]
            }
        },
        testEmit() {
            this.$emit('trigger')
        },
        async fetchLottery() {
            let currentdate = new Date().toISOString().slice(0,10)
            
            if (this.alluser.length > 0) {
                let allusers = this.alluser.map(item => ({
                    id: item.id,
                    name: item.name,
                    generatedid: item.generatedid,
                }))
                this.selecteduser = allusers[Math.floor(Math.random() * allusers.length)];
                let param = {transactiondate: currentdate}
                let response = await axios.post('api/fetch/lottery/position',param)
                this.position = response.data.position
                this.$modal.show('lotteryShow')
            }
        },
        close() {
            this.position = 0
            this.selecteduser = null

            this.rewards = ''
            this.$modal.hide('lotteryShow')
        },
        lotterysave() {
            // send selected user to lottery api
            if (this.rewards != '') {
                let param = {
                    customerid: this.selecteduser.id,
                    customername: this.selecteduser.name,
                    generatedid: this.selecteduser.generatedid,
                    rewards: this.rewards,
                    position: this.position ?? '1',
                }
                this.$http.post('api/lottery/record/save', param)
                    .then((response) => {
                        console.log(response)
                        this.$notify({
                            title: 'Success',
                            message: 'Record saved successfully!',
                            type: 'success',
                            duration: 2000
                        })
                        this.close()
                    })
                    .catch(error => console.log(error));
            }
        }

    }
}
</script>